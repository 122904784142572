import {Component, HostListener, OnInit, VERSION} from '@angular/core';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { MessageService } from 'primeng/api';
import { WindowService } from './_services/window.service';
import { FmaService } from './_services/fmaLibrary.service';
import { WallboxService } from './_services/wallbox.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './_services/loader.service';
import { AmplitudeService } from './_services/amplitude.service';
import { AppSendEvents } from './app-send-events';
import {TransferState} from '@angular/platform-browser';

declare global {
	interface Window {
		fma: any;
	}
}
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	hamMenu: boolean;
	version = VERSION.full;
	webview = environment.webview;
	production = environment.production;
	siemens = environment.siemens;
	catToken: any;
	refreshToken: any;
	userGuid: any;
	paramsSubscription: any;
	queryParams: any;
	repeatCounter: number;
	userLanguage: string;
	languages = [];
	isUserFromNA: boolean;
	isUserFromUSA: boolean;
	userCountry: string;
	vendor = environment.vendorCodes;

	constructor(private readonly router: Router,
				private readonly wallboxService: WallboxService,
				private readonly windowService: WindowService,
				private readonly fmaService: FmaService,
				private readonly translate: TranslateService,
				private readonly loaderService: LoaderService,
				private readonly amplitudeService: AmplitudeService,
				private readonly appSendEvents: AppSendEvents,
				private readonly transferState: TransferState) {
		translate.setDefaultLang('en-US');
	}

	ngOnInit() {
		this.repeatCounter = 0;
		this.cleanupQueryParams();
		this.loadFMAScript();
		this.amplitudeService.initialize();
	}

	loadFMAScript() {
		console.log('starting to load FMA');
		const scriptUrl = environment.FMA.FMA_URL + 'etc/fd/fma/bundle.js';
		const js = document.createElement('script');
		js.setAttribute('src', scriptUrl);
		js.setAttribute('data-fma-script', '');
		js.setAttribute('data-client-id', environment.FMA.AZURE_CID);
		js.setAttribute('data-app-id', environment.FMA.APP_ID);
		// let baseUrl: any;
		// baseUrl = origin.concat(pathname.split('/').slice(1, 3));
		// console.log("baseurl--" + baseUrl);
		// js.setAttribute('data-base-path', environment.FMA.BASE_URL);
		let callbackUrl: any;
		// callbackUrl = baseUrl.concat('fma_callback');
		js.setAttribute('data-redirect-url', environment.FMA.REDIRECT_URL);
		// js.setAttribute('data-logout-url', baseUrl.join('/'));
		js.setAttribute('data-property-key', environment.FMA.PROPERTY_SPECIFIC_CONTENT_KEY);
		js.setAttribute('data-region', 'USA');
		js.setAttribute('data-lang', 'en_us');
		js.setAttribute('data-enable-guest-guid', 'false');
		document.getElementsByTagName('head')[0].appendChild(js);
		console.log('loaded to load FMA');
		this.handleAuthTokensOnWebView();
	}

	handleDefaultRedirects() {
		if (this.router.url === '' || this.router.url === '/') {
			this.router.navigate(['app-startup'], { queryParamsHandling: 'preserve'});
		}
	}

	handleAuthTokensOnWebView() {
		if (!this.catToken && this.repeatCounter < 30) {
			this.fetchTokensFromFMA();
			this.loaderService.show();
			this.repeatCounter++;
			setTimeout(() => this.handleAuthTokensOnWebView(), 250);
		} else {
			if(this.catToken) {
				this.wallboxService.storeCatToken(this.catToken, this.refreshToken, true);
			}
			this.getUsersLanguage();
		}
	}

	fetchTokensFromFMA() {
		this.windowService.load();
		this.catToken = this.fmaService.getAccessTokenFromWindow();
		console.log(this.catToken);
		this.refreshToken = this.fmaService.getRefreshTokenFromWindow();
		this.userGuid = this.fmaService.getUserGuidFromWindow();
		if (this.catToken) {
			this.wallboxService.storeCatToken(this.catToken, this.refreshToken, true);
		}
		if (this.userGuid) {
			this.wallboxService.storeUserGuid(this.userGuid);
		}
	}

	cleanupQueryParams() {
		this.queryParams = null;
	}

	getUsersLanguage() {
		this.wallboxService.getUserProfile().subscribe(
			(data: any) => {
				this.handleUserCountry(data);
				this.handleUserProfileData(data);
				this.hideLoaderAndHanldeRedirects();
			});
	}

	handleUserProfileData(data: any) {
		this.userLanguage = data.result.profile.preferredLanguage;
		this.userCountry = data.result.profile.country;
		this.languages = this.wallboxService.getAllLanguages();
		const defaultLanguage = this.wallboxService.getDefaultLanguage();
		let languagetoBeStored = defaultLanguage;
		if (this.checkUserLanguageIsInTranslationList()) {
			this.translate.use(this.userLanguage);
			languagetoBeStored = this.userLanguage;
		}
		this.wallboxService.storeUserLanguage(languagetoBeStored);
		this.wallboxService.storeUserCountry(this.userCountry);
		const userId = data.result.profile.userGuid;
		this.amplitudeService.setUserID(userId.toLowerCase());
	}

	handleUserCountry(data) {
		const country = data.result.profile.country;
		this.isUserFromNA = this.checkCountryIsNA(country);
		if (this.siemens) {
			this.wallboxService.setIsUserFromNA(this.isUserFromNA);
		}
	}

	hideLoaderAndHanldeRedirects() {
		if (this.webview === true) {
			this.loaderService.hide();
			this.handleDefaultRedirects();
		}
	}

	checkUserLanguageIsInTranslationList() {
		return this.languages.includes(this.userLanguage);
	}

	checkCountryIsNA(country: string) {
		const naCountries = this.wallboxService.getNACountries();
		this.isUserFromUSA = country === 'USA';
		this.wallboxService.setIsUserFromUSA(this.isUserFromUSA);
		if (naCountries.includes(country)) {
			return true;
		} else {
			return false;
		}
	}

	getWallboxType(serialNumber) {
		const vendorCode = serialNumber.substring(0, 3).toUpperCase();
		return this.vendor['SIEMENS'].includes(vendorCode) ? '80A' : '48A';
	}

	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}
}
