import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class WindowService {
	private windowObject: any;
	private deepLinkUrl: any;
	private windowAppObject: any;
	private windowExternalApp: any;
	private deeplinkUrl: string;
	private urlScheme: string;
	private packageName: string;
	private appStoreURL: string;
	private playStoreURL: string;
	private fma: any;

	load() {
		this.windowObject = window;
		this.windowAppObject = Window;
		this.deepLinkUrl = this.windowObject.location.href;
		this.windowExternalApp = this.windowObject.fma;
		console.log('WindowService: FMA object loaded', this.fma);
	}

	getFMAObject() {
		return this.windowObject.fma;
	}

	waitForFMAObject(retries = 200, delay = 1000): Promise<any> {
		return new Promise((resolve, reject) => {
			const checkFMA = (attempts: number) => {
				this.load();
				if (this.windowObject.fma) {
					resolve(this.windowObject.fma);
				} else if (attempts > 0) {
					setTimeout(() => checkFMA(attempts - 1), delay);
				} else {
					reject('FMA object is not available');
				}
			};
			checkFMA(retries);
		});
	}

	queryWindow(input) {
		return this.windowObject[input];
	}

	getDeepLinkUrl() {
		return this.deepLinkUrl;
	}

	openPlaystoreAppStoreUrl(packageName, OSType, deeplinkUrl, urlScheme, appStoreURL) {
		if (OSType === 'Android') {
			window.location.href = this.playStoreURL;
		} else {
			window.location.href = this.appStoreURL;
		}
	}

	setDeeplinkParams(wallboxType) {
		if (wallboxType === '80A') {
			this.deeplinkUrl = 'fordchargestationpro://app';
			this.urlScheme = 'fordchargestationpro://';
			this.packageName = 'com.siemens.ford80A';
			this.appStoreURL = 'https://apps.apple.com/us/app/chargestationpro/1607972327';
			this.playStoreURL = 'https://play.google.com/store/apps/details?id=com.siemens.ford80A';
		} else {
			this.deeplinkUrl = 'webasto://FCSETUP?calling-app=fp';
			this.urlScheme = 'webasto://';
			this.packageName = 'com.webasto.ford';
			this.appStoreURL = 'https://apps.apple.com/us/app/ford-charge-station-setup/id1497123314';
			this.playStoreURL = 'https://play.google.com/store/apps/details?id=com.webasto.ford';
		}
		return {
			deeplinkUrl: this.deeplinkUrl,
			urlScheme: this.urlScheme,
			packageName: this.packageName,
			appStoreURL: this.appStoreURL
		};
	}

	findDeviceType() {
		let OSType;
		if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
			OSType = 'Android';
		}
		if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
			OSType = 'iOS';
		}
		return OSType;
	}
}
