import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {WindowService} from '../_services/window.service';

@Component({
	selector: 'app-fma-login',
	templateUrl: './fma-login.component.html',
	styleUrls: ['./fma-login.component.css']
})
export class FmaLoginComponent implements AfterViewInit {

	@ViewChild('loginButton', { static: true }) loginButton: ElementRef;
	isTestEnvironment = false;

	constructor(private readonly windowService: WindowService,
				private renderer: Renderer2) {
	}

	ngAfterViewInit() {
		sessionStorage.setItem('catToken', '');
		sessionStorage.setItem('refreshToken', '');
		if (!this.isTestEnvironment) {
			this.renderer.selectRootElement(this.loginButton.nativeElement).click();
		}
	}

	login() {
		this.windowService.waitForFMAObject().then(fma => {
			if (fma && typeof fma.login === 'function') {
				fma.login();
			} else {
				console.error('FMA object is not defined or does not have a login method', fma);
			}
		}).catch(error => {
			console.error(error);
		});
	}

}
