import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WallboxService } from '../_services/wallbox.service';
import { FmaService } from '../_services/fmaLibrary.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {WindowService} from '../_services/window.service';

@Component({
	selector: 'app-startup',
	templateUrl: './app-startup.component.html',
	styleUrls: ['./app-startup.component.css']
})
export class AppStartupComponent implements OnInit {
	isLoading = true;
	chargePointData: any;
	webview = environment.webview;
	ownChargePointResult: any;
	sharedChargePointResult: any;
	catToken: any;
	refreshToken: any;
	userGuid: any;
	private subscription: Subscription;
	isUserFromNA: boolean;
	startSetupBtn = 'startSetupBtn';
	learnMoreBtn = 'learnMoreBtn';

	constructor(private readonly router: Router,
				private readonly route: ActivatedRoute,
				private readonly wallboxService: WallboxService,
				private readonly fmaService: FmaService,
				private readonly windowService: WindowService,
				private readonly translate: TranslateService) {
	}

	ngOnInit() {
		this.checkCatTokenIsPresent();
		this.reloadScreenOnReceiveUserLanguage();
		this.isUserFromNA = this.wallboxService.getIsUserFromNA();
	}


	fetchTokensFromFMA() {
		this.catToken = this.fmaService.getAccessTokenFromWindow();
		console.log(this.catToken);
		this.refreshToken = this.fmaService.getRefreshTokenFromWindow();
		this.userGuid = this.fmaService.getUserGuidFromWindow();
		if (this.catToken) {
			this.wallboxService.storeCatToken(this.catToken, this.refreshToken, true);
		}
		if (this.userGuid) {
			this.wallboxService.storeUserGuid(this.userGuid);
		}
	}

	reloadScreenOnReceiveUserLanguage() {
		this.subscription = this.wallboxService.languageState
			.subscribe((lang) => {
				this.ngOnInit();
			});
	}

	checkCatTokenIsPresent() {
		this.catToken = this.wallboxService.getCatToken();
		if (this.catToken) {
			this.goToMyWallboxes();
		} else {
			this.isLoading = false;
			this.router.navigate(['error']);
		}
	}

	goToMyWallboxes() {
		this.wallboxService.getchargePointsList('all').subscribe(
			(data: any) => {
				this.handleGetChargePointListData(data);
			}, error => {
				this.isLoading = false;
			},
			() => {
				this.isLoading = false;
			});
	}

	handleGetChargePointListData(data: any) {
		this.ownChargePointResult = data.result.ownChargePointDetailsList;
		this.sharedChargePointResult = data.result.sharedChargePointDetailsList;
		if (this.ownChargePointResult.length > 1 || this.sharedChargePointResult.length > 1 ||
			(this.ownChargePointResult.length >= 1 && this.sharedChargePointResult.length >= 1)) {
			this.router.navigate(['manage-wallboxes']);
		} else if (this.ownChargePointResult.length === 1) {
			this.router.navigate(['wallbox-details-dashboard/own'], {
				queryParams: {
					id: this.ownChargePointResult[0].chargingStationId,
					status: true,
					serialNumber: this.ownChargePointResult[0].serialNumber
				}
			});
		} else if (this.sharedChargePointResult.length === 1) {
			this.router.navigate(['wallbox-details-dashboard/shared'], {
				queryParams: {
					id: this.sharedChargePointResult[0].chargingStationId,
					status: true,
					serialNumber: this.sharedChargePointResult[0].serialNumber
				}
			});
		}
	}

	goToSetup() {
		this.wallboxService.setWallboxType('48A');
		this.router.navigate(['app-setup']);
	}

	goToSelectYourStation() {
		this.router.navigate(['wallbox-setup/select-your-station']);
	}

	goToAppStep() {
		this.router.navigate(['wallbox-setup/app-steps']);
	}
}
