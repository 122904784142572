import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from './_guards/auth.guard';
import {AppStartupComponent} from './app-startup/app-startup.component';
import {SetupConfigConfirmComponent} from './setup-config-confirm/setup-config-confirm.component';
import {GeneralErrorComponent} from './general-error/general-error.component';
import {AppSetupComponent} from './app-setup/app-setup.component';
import {FmaLoginComponent} from './fma-login/fma-login.component';

export const routes: Routes = [
	{
		path: 'fma-login',
		component: FmaLoginComponent
	},
	{
		path: 'app-startup',
		component: AppStartupComponent
	},
	{
		path: 'app-setup',
		component: AppSetupComponent
	},
	{
		path: 'setup-config-confirm',
		component: SetupConfigConfirmComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'error',
		component: GeneralErrorComponent
	},
	// { path: '**', redirectTo: '' },
	{ path: 'wallbox-details-dashboard', loadChildren: () => import('./dashboard/wallbox-dashboard.module').then(m => m.WallboxDashboardModule) },
	{ path: 'manage-wallboxes', loadChildren: () => import('./manage-wallboxes/manage-wallboxes.module').then(m => m.ManageWallboxesModule) },
	{ path: 'wallbox-setup', loadChildren: () => import('./wallbox-setup/wallbox-setup.module').then(m => m.WallboxSetupModule) },
	{ path: 'invite-user', loadChildren: () => import('./invite-user/invite-user.module').then(m => m.InviteUserModule) },
	{ path: 'charge-events', loadChildren: () => import('./charge-events/charge-events.module').then(m => m.ChargeEventsModule) }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		useHash: true,
		scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled',
		enableTracing: false,
		preloadingStrategy: PreloadAllModules
	})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
