import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { WindowService } from '../_services/window.service';
import { WallboxService } from '../_services/wallbox.service';

@Component({
	selector: 'app-steps-add-device',
	templateUrl: './setup-config-confirm.html',
	styleUrls: ['./setup-config-confirm.css'],
	providers: [ConfirmationService]
})
export class SetupConfigConfirmComponent implements OnInit {
	position: string;
	setupConfigConfirm = false;
	chargePointId: any;
	wallboxType: string;

	constructor(private readonly router: Router,
				private readonly windowService: WindowService,
				private confirmationService: ConfirmationService,
				private wallboxService: WallboxService,
				private readonly route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.chargePointId = params.id;
		});
		sessionStorage.setItem('chargePointId', this.chargePointId);
		this.wallboxType = this.wallboxService.getWallboxType();
	}

	showConfirmSetupConfig() {
		this.setupConfigConfirm = true;
	}

	closeSetupConfig() {
		this.setupConfigConfirm = false;
		this.router.navigate(['setup-config-confirm'], {
			queryParams: {
				id: this.chargePointId
			}, queryParamsHandling: 'merge'
		});
	}

	openWebastoAppForAddWallbox() {
		const OSType = this.windowService.findDeviceType();
		const deeplinkParams = this.windowService.setDeeplinkParams(this.wallboxType);
		this.windowService.openPlaystoreAppStoreUrl(deeplinkParams.packageName, OSType, deeplinkParams.deeplinkUrl, deeplinkParams.urlScheme, deeplinkParams.appStoreURL);
	}

	moveToPrevPage() {
		this.router.navigate(['wallbox-details-dashboard/own'], {
			queryParams: {
				id: this.chargePointId === undefined ? sessionStorage.getItem('chargePointId') : this.chargePointId
			}, queryParamsHandling: 'merge'
		});
	}
}

